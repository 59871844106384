import React, { MouseEvent, useState } from 'react';
import { Button, Menu, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmber';
import MenuIcon from '@mui/icons-material/Menu';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import { useDispatch } from 'store';
import { setLoading } from 'store/slices/submission';
import dayjs from 'dayjs';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { FileMappedToNotification, GetZipFileSignedURL } from 'ui-component/records/types';
import { openSnackbar } from 'store/slices/snackbar';

export type GridToolbarActionsProps = {
    onDelete: () => Promise<void>;
    onClickBulkEdit: () => void;
    trigger: string;
    rowsSelectedAmount: number;
    recordType: string;
    getZipFileUrl: LazyQueryExecFunction<GetZipFileSignedURL, OperationVariables>;
    show?: boolean;
    showDelete?: boolean;
    showBulkDownload?: boolean;
    showBulkUpdate?: boolean;
};

export const GridToolbarActions = ({
    onDelete,
    onClickBulkEdit,
    rowsSelectedAmount,
    recordType,
    getZipFileUrl,
    show,
    trigger,
    showDelete,
    showBulkDownload,
    showBulkUpdate
}: GridToolbarActionsProps) => {
    const apiRef = useGridApiContext();
    const modal = useConfirmationModalContext();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const recordCount = apiRef.current.getSelectedRows().size;

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

    const onClickDelete = async () => {
        setAnchorEl(null);
        await modal.showConfirmation({
            title: 'Delete',
            icon: <WarningIcon color="warning" sx={{ marginLeft: '1rem' }} fontSize="large" />,
            content: `You are about to delete ${
                recordCount === 1 ? `${recordCount} record` : `${recordCount} records`
            }. Do you wish to continue?`,
            loadingText: 'Deleting',
            forwardButtonText: 'Delete',
            onForward: handleDelete,
            onAction: null,
            showCheckbox: false,
            actionType: 'delete',
            sectionTitle: 'record_grid',
            recordType: trigger || ''
        });
    };

    const handleDelete = async () => {
        dispatch(setLoading(true));
        await onDelete();
        dispatch(setLoading(false));
    };

    const tooManyRecordsSelectedWarning = (action: string) => {
        dispatch(
            openSnackbar({
                open: true,
                message: `Warning`,
                subtitle: `The maximum number of records that can be ${action} is 100.`,
                variant: 'alert',
                alert: {
                    color: 'warning'
                },
                close: false
            })
        );
        setAnchorEl(null);
    };

    return (
        <>
            {!!recordCount && show && (showDelete || showBulkDownload || showBulkUpdate) && (
                <>
                    <Button
                        sx={({ palette }) => ({
                            color: palette.primary[400],
                            backgroundColor: palette.primary[300],
                            borderRadius: '30px',
                            padding: '8px 16px'
                        })}
                        startIcon={<MenuIcon />}
                        size="medium"
                        color="primary"
                        onClick={handleClick}
                    >
                        Actions
                    </Button>
                    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
                        <Paper sx={{ width: 260, maxWidth: '100%' }}>
                            {rowsSelectedAmount <= 100 ? (
                                <MenuList>
                                    {showBulkUpdate && (
                                        <MenuItem
                                            onClick={() => {
                                                setAnchorEl(null);
                                                onClickBulkEdit();
                                            }}
                                            divider
                                        >
                                            Bulk Edit
                                        </MenuItem>
                                    )}

                                    {showBulkDownload && (
                                        <MenuItem
                                            onClick={async () => {
                                                setAnchorEl(null);
                                                const downloadId = `${recordType}_${[...apiRef.current?.getSelectedRows().keys()].join(
                                                    '_'
                                                )}`;
                                                const bulkDownload = JSON.parse(
                                                    localStorage.getItem('bulk-download-data') ?? '[]'
                                                ) as FileMappedToNotification[];
                                                const newBulkDownload = [
                                                    ...bulkDownload.filter((item: any) => item?.id !== downloadId),
                                                    {
                                                        id: downloadId,
                                                        files: rowsSelectedAmount,
                                                        fileName: `${recordType}_documents_${dayjs().format('YYYY-MM-DD HH:mm')}.zip`,
                                                        status: 'Pending',
                                                        fileUrl: '',
                                                        progress: 50,
                                                        started: dayjs().format('YYYY-MM-DD HH:mm'),
                                                        new: true
                                                    }
                                                ];
                                                localStorage.setItem('bulk-download-data', JSON.stringify(newBulkDownload));
                                                window.dispatchEvent(new CustomEvent('bulk-download', {}));
                                                getZipFileUrl({
                                                    variables: {
                                                        data: [...apiRef.current?.getSelectedRows().keys()].map((el) => +el)
                                                    }
                                                })
                                                    .then((res) => {
                                                        const bulkDownloadOld = JSON.parse(
                                                            localStorage.getItem('bulk-download-data') ?? '[]'
                                                        );
                                                        const bulkDownloadNew = bulkDownloadOld.map((item: any) => {
                                                            if (item?.id === downloadId) {
                                                                return {
                                                                    ...item,
                                                                    status: 'Completed',
                                                                    fileUrl: res?.data?.getZipFileSignedUrl,
                                                                    progress: 100
                                                                };
                                                            }
                                                            return item;
                                                        });
                                                        localStorage.setItem('bulk-download-data', JSON.stringify(bulkDownloadNew));
                                                        window.dispatchEvent(new CustomEvent('bulk-download', {}));
                                                    })
                                                    .catch((err) => {
                                                        const bulkDownloadOld = JSON.parse(
                                                            localStorage.getItem('bulk-download-data') ?? '[]'
                                                        );
                                                        const bulkDownloadNew = bulkDownloadOld.map((item: any) => {
                                                            if (item?.id === downloadId) {
                                                                return {
                                                                    ...item,
                                                                    status: 'Failed',
                                                                    progress: 0
                                                                };
                                                            }
                                                            return item;
                                                        });
                                                        localStorage.setItem('bulk-download-data', JSON.stringify(bulkDownloadNew));
                                                        window.dispatchEvent(new CustomEvent('bulk-download', {}));
                                                    });
                                            }}
                                            divider
                                        >
                                            Bulk Download
                                        </MenuItem>
                                    )}

                                    {showDelete && <MenuItem onClick={onClickDelete}>Delete</MenuItem>}
                                </MenuList>
                            ) : (
                                <MenuList>
                                    <MenuItem
                                        onClick={() => {
                                            tooManyRecordsSelectedWarning('edited');
                                        }}
                                        divider
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography sx={{ color: '#D9D9D9' }}>Bulk Edit</Typography>
                                        <Typography sx={{ color: '#FF9800', fontWeight: 'bold' }}>(Max. 100)</Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            tooManyRecordsSelectedWarning('downloaded');
                                        }}
                                        divider
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography sx={{ color: '#D9D9D9' }}>Bulk Download</Typography>
                                        <Typography sx={{ color: '#FF9800', fontWeight: 'bold' }}>(Max. 100)</Typography>
                                    </MenuItem>
                                    {showDelete && (
                                        <MenuItem
                                            onClick={() => {
                                                tooManyRecordsSelectedWarning('deleted');
                                            }}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography sx={{ color: '#D9D9D9' }}>Delete</Typography>
                                            <Typography sx={{ color: '#FF9800', fontWeight: 'bold' }}>(Max. 100)</Typography>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            )}
                        </Paper>
                    </Menu>
                </>
            )}
        </>
    );
};
