import { Chip } from '@mui/material';

export type ToggleChipProps = {
    value: boolean;
    onChange: (newVal: boolean) => void;
    disabled?: boolean;
    trueLabel?: string;
    falseLabel?: string;
};

export const ToggleChip = ({ value, onChange, disabled, falseLabel = 'Inactive', trueLabel = 'Active' }: ToggleChipProps) => {
    const handleChange = (newVal: boolean) => () => {
        onChange(newVal);
    };

    if (value)
        return (
            <Chip
                disabled
                onClick={handleChange(false)}
                label={trueLabel}
                size="small"
                sx={(theme) => ({
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                    color: theme.palette.success.dark
                })}
            />
        );

    return (
        <Chip
            disabled
            onClick={handleChange(true)}
            label={falseLabel}
            size="small"
            sx={(theme) => ({
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                color: theme.palette.orange.dark
            })}
        />
    );
};
