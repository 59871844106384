/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'store';
import { openDrawer } from 'store/slices/menu';
import { useCustomListValues } from 'hooks/useCustomListValues';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import useMyRole from 'hooks/useMyRole';
import { generateClearedRecordData, getListIdsFromHeaders, splitHeaders } from 'ui-component/records/utils/headerHelpers';
import { IObjectValues, IRecordField, IRecordHeaders } from 'ui-component/records/types';
import { IFormatDataValues } from '../types';
import { generateInitialState, getDataToCreate, getDataToUpdate } from '../utils';
import { getViewFields, getAditionalFieldsValues, getObjectValuesFromListValues, PanelTitle, LogReportMode } from './utils';
import EditPanel, { CreatePanelForm, EditPanelForm } from './EditPanel/v2';
import ViewPanel from './ViewPanel';
import { LogReportPanel } from './LogReportPanel';
import { HeaderPanel } from './HeaderPanel';
import { filterNotAllowedFields } from 'ui-component/records/utils';
import { Footer } from './Footer';
import { useRecordData } from './hooks';
import ObjectPanelView from 'ui-component/records/RecordGrid/components/ObjectPanelView';
import ObjectPanelEditForm from 'ui-component/records/RecordGrid/components/ObjectPanelEditForm';
import { QUERY_GET_OBJECT_PROPERTIES } from 'graphql/queries/customObjects';
import { FindObjectProperty, FindObjectPropertyVariables, ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { useLazyQuery } from '@apollo/client';
import { DynamicObject } from 'views/TenantProfileSettings/components/types';
import { generateCurrentValuesByPropertyId } from 'views/backoffice/CustomLists/utils';
import { FooterHistoryPanel } from './FooterHistoryPanel';

interface PropertiesPanelProps {
    loading?: boolean;
    recordData?: IRecordHeaders | null;
    recordType?: string;
    isCreate?: boolean;
    hasFile?: boolean;
    onSubmit: (recordType: number, data: IFormatDataValues, showAlerts: boolean) => Promise<boolean>;
    isEditMode?: any;
    onUploadFile: () => void;
    // PDF Controllers
    onToggleLineItems?: () => void;
    fileAdded?: boolean;
    onToggleComments?: () => void;
    onToggleAttachments?: () => void;
}
export interface IForm {
    recordType?: number;
    recordNumber: string;
    statusId: number | null;
    problemCodeId: number | null;
    approvedBy: number | null;
    recordDate: string | null;
    dueDate: string | null;
    poNumber: string;
    assignedTo: number | null;
}

export const PropertiesPanelv3 = ({
    loading,
    recordData = null,
    recordType,
    isCreate,
    onSubmit,
    onUploadFile,
    hasFile = false,
    isEditMode,
    onToggleLineItems,
    fileAdded = false,
    onToggleComments,
    onToggleAttachments
}: PropertiesPanelProps) => {
    const modal = useConfirmationModalContext();
    const roleId = useMyRole();
    const dispatch = useDispatch();

    const [changePanel, setChangePanel] = useState<PanelTitle>(isCreate ? PanelTitle.Edit : PanelTitle.View);
    const [logReportMode, setLogReportMode] = useState<LogReportMode>(LogReportMode.All);
    const [showFullHistory, setShowFullHistory] = useState(false);
    const [recordTypeId, setRecordTypeId] = useState(recordType);
    const [initialFormState, setInitialFormState] = useState<Partial<CreatePanelForm>>({});

    const methods = useForm<CreatePanelForm | EditPanelForm>({
        mode: 'onChange'
    });

    const { fieldAccess, headers, loading: loadingRecordData } = useRecordData({ recordTypeId: Number(recordTypeId) });
    const listIds = getListIdsFromHeaders(headers);
    const { loading: loadingListValues, listValuesByListId } = useCustomListValues(listIds);

    const checkAllowedFields = useMemo(
        () => filterNotAllowedFields(fieldAccess, Number(recordTypeId), roleId),
        [fieldAccess, recordTypeId, roleId]
    );

    const { baseFieldHeaders, additionalFieldHeaders } = useMemo(
        () => splitHeaders(headers, checkAllowedFields),
        [checkAllowedFields, headers]
    );

    // Objects States
    const [objectProperties, setObjectProperties] = useState<(ObjectProperty & { value: string })[]>([]);
    const [selectedObjectToEdit, setSelectedObjectToEdit] = useState<{ id: number; name: string } | null>(null);
    const [inMemoryObjectProperties, setInMemoryObjectProperties] = useState<{ [key: string]: any }>({});
    const [prevChangePanel, setPrevChangePanel] = useState<PanelTitle>(PanelTitle.View);
    const [isAllowedToEditObject, setIsAllowedToEditObject] = useState(true);

    // Objects Queries
    const [getObjectProperties, { loading: loadingObjectProperties }] = useLazyQuery<FindObjectProperty, FindObjectPropertyVariables>(
        QUERY_GET_OBJECT_PROPERTIES
    );
    // Objects Control
    const handleOpenViewObjectPanel = async (
        field: IRecordField,
        fieldName: string,
        editable: boolean,
        selectedObject?: Pick<DynamicObject, 'id' | 'objectValues' | 'objectDefinition'> | null
    ) => {
        const isListOfObjects = !!field?.listType?.objectDefinition?.id;

        const objectDefinitionIds = selectedObject
            ? +selectedObject.objectDefinition.id
            : isListOfObjects
            ? field.listType?.objectDefinition?.id
            : field.objectDefinition.id;

        const { data: objectPropertiesData } = await getObjectProperties({
            variables: { data: { objectDefinitionIds } }
        });

        // When the user selects an object but want to see it before save it in the field
        if (selectedObject) {
            const currentValuesByPropertyId = generateCurrentValuesByPropertyId(selectedObject.objectValues);
            setObjectProperties(
                objectPropertiesData?.findObjectProperty.map((el) => ({
                    ...el,
                    value: currentValuesByPropertyId[+el.id]?.value || ''
                })) || []
            );

            setIsAllowedToEditObject(false);
            setSelectedObjectToEdit({ id: 0, name: fieldName });
            setChangePanel(PanelTitle.ViewObject);
            return;
        }

        const fieldData = recordData?.additionalFields?.find((additionalField) => {
            const fieldId = additionalField.tag.split(';')[2];
            return Number(fieldId) === Number(field.id);
        });

        setSelectedObjectToEdit({ id: Number(fieldData?.id), name: fieldName });

        const valuesFromObjectList = getObjectValuesFromListValues(listValuesByListId, field?.listType?.id, fieldData?.value);

        const values = isListOfObjects ? (valuesFromObjectList as IObjectValues[]) : fieldData?.objectValue?.objectValues || [];

        if (!isCreate) {
            let mappedProperties = [];
            if (!inMemoryObjectProperties[fieldName]) {
                mappedProperties =
                    objectPropertiesData?.findObjectProperty
                        .map((property) => ({
                            ...property,
                            value: values.find((el) => Number(el.objectProperty.id) === Number(property.id))?.value || ''
                        }))
                        .filter((property) => property.enabled)
                        .sort((a, b) => {
                            const orderA = a.order; // ignore upper and lowercase
                            const orderB = b.order; // ignore upper and lowercase
                            if (orderA < orderB) {
                                return -1;
                            }
                            if (orderA > orderB) {
                                return 1;
                            }
                            return 0;
                        }) || [];
            } else {
                mappedProperties = inMemoryObjectProperties[fieldName];
            }
            setObjectProperties(mappedProperties || []);
        } else if (isCreate) {
            const mappedProperties = objectPropertiesData?.findObjectProperty
                .map((property) => ({
                    ...property,
                    value: ''
                }))
                .sort((a, b) => {
                    const orderA = a.order;
                    const orderB = b.order;
                    if (orderA < orderB) {
                        return -1;
                    }
                    if (orderA > orderB) {
                        return 1;
                    }
                    return 0;
                });
            setObjectProperties(inMemoryObjectProperties[fieldName] || mappedProperties);
        } else {
            setObjectProperties([]);
        }
        setIsAllowedToEditObject(editable);
        setPrevChangePanel(changePanel);
        setChangePanel(PanelTitle.ViewObject);
    };

    const handleUpdateObject = async (newProperties: (ObjectProperty & { value: string })[]) => {
        const stateInsert: { [key: string]: any } = {};
        stateInsert[String(selectedObjectToEdit?.name)] = newProperties;
        setInMemoryObjectProperties((prev) => ({ ...prev, ...stateInsert }));
        setPrevChangePanel(PanelTitle.View);
        setChangePanel(PanelTitle.Edit);
    };

    const handleSaveEditObjectFormActions = async (values: { [key: string]: string }, modalTitle: string) => {
        await modal.showConfirmation({
            title: modalTitle,
            content: '',
            loadingText: 'Saving Changes',
            forwardButtonText: 'Save changes',
            actionButtonText: 'Discard Changes',
            onForward: async () => {
                modal.toogleLoading(true);
                const newObjectProperties = objectProperties.map((item: ObjectProperty & { value: string }) => ({
                    ...item,
                    value: values[item.name]
                }));
                setObjectProperties(newObjectProperties);
                handleUpdateObject(newObjectProperties);
                if (!isCreate) {
                    const newProperties: { [key: string]: any } = {};
                    newProperties[String(selectedObjectToEdit?.name)] = newObjectProperties;
                    const formData = { ...methods.getValues(), ...inMemoryObjectProperties, ...newProperties };
                    const clearedData = generateClearedRecordData(initialFormState, formData);
                    await onSubmit(
                        Number(recordTypeId),
                        getDataToUpdate(clearedData, baseFieldHeaders, additionalFieldHeaders, recordData?.additionalFields || []),
                        true
                    );
                }
                modal.toogleLoading(false);
            },
            onAction: () => setChangePanel(prevChangePanel),
            showCheckbox: true,
            actionType: `${modalTitle === 'Do you want to save this changes?' ? `save` : `discard`}_object_changes`,
            sectionTitle: 'record_viewer',
            recordType: '',
            hideCloseBtn: true
        });
    };

    // Values
    // const aditionalFields = useMemo(
    //     () =>
    //         additionalFieldHeaders && recordData?.additionalFields
    //             ? getAditionalFieldsValues(additionalFieldHeaders, recordData?.additionalFields)
    //             : {},
    //     [additionalFieldHeaders, recordData?.additionalFields]
    // );

    // Fields for view Panel
    const viewFields = useMemo(
        () => getViewFields(recordData, baseFieldHeaders, additionalFieldHeaders, fieldAccess, listValuesByListId),
        [additionalFieldHeaders, baseFieldHeaders, recordData, fieldAccess, listValuesByListId]
    );

    const toggleEditForm = () => {
        const newPanelState = changePanel === PanelTitle.Edit ? PanelTitle.View : PanelTitle.Edit;
        setChangePanel(newPanelState);
        if (newPanelState === PanelTitle.Edit) methods.reset({ ...initialFormState });
    };

    const toggleLogReport = () => {
        switch (changePanel) {
            case PanelTitle.EditObject:
            case PanelTitle.ViewObject:
                setLogReportMode(LogReportMode.Objects);
                break;

            default:
                setLogReportMode(LogReportMode.All);
                break;
        }
        setChangePanel((s) => (s === PanelTitle.LogReport ? PanelTitle.View : PanelTitle.LogReport));
    };

    const handleClickCancel = async () => {
        const formData = methods.getValues();
        const clearedData = generateClearedRecordData(initialFormState, formData);
        if (Object.entries(clearedData).filter(([_key, val]) => val !== null).length === 0) setChangePanel(PanelTitle.View);
        else
            await modal.showConfirmation({
                title: 'Are you sure you want to discard your changes?',
                content: '',
                loadingText: 'Saving Changes',
                forwardButtonText: 'Discard Changes',
                actionButtonText: 'Save changes',
                onForward: async () => setChangePanel(PanelTitle.View),
                onAction: handleSubmit,
                showCheckbox: true,
                actionType: 'discard',
                sectionTitle: 'record_viewer',
                recordType: '',
                hideCloseBtn: true
            });
    };

    // Submit handlers
    const handleClickSave = async () => {
        if (await methods.trigger())
            await modal.showConfirmation({
                title: hasFile ? 'Do you want to save this changes?' : 'This record doesnt contain a document',
                content: '',
                loadingText: 'Saving Changes',
                forwardButtonText: hasFile ? 'Save changes' : 'Save Anyway',
                actionButtonText: hasFile ? 'Discard Changes' : 'Attach a Document',
                onForward: handleSubmit,
                onAction: hasFile ? async () => setChangePanel(PanelTitle.View) : onUploadFile,
                showCheckbox: true,
                actionType: isCreate ? 'create' : 'edit',
                sectionTitle: 'record_viewer_save',
                recordType: '',
                hideCloseBtn: true
            });
    };

    const handleSubmit = async () => {
        modal.toogleLoading(true);
        const formData = methods.getValues();
        const clearedData = generateClearedRecordData(initialFormState, formData);

        if (!isCreate && Object.entries(clearedData).filter(([_key, val]) => val !== null).length === 0 && !fileAdded) {
            setChangePanel(PanelTitle.View);
            return;
        }

        const res = await onSubmit(
            Number(recordTypeId),
            isCreate
                ? getDataToCreate(formData, baseFieldHeaders, additionalFieldHeaders, inMemoryObjectProperties)
                : getDataToUpdate(clearedData, baseFieldHeaders, additionalFieldHeaders, recordData?.additionalFields || []),
            true
        );
        modal.toogleLoading(false);
        if (res) setChangePanel(PanelTitle.View);
    };

    // create form for edit record
    useEffect(() => {
        if (!recordData || !additionalFieldHeaders || !baseFieldHeaders || isCreate) return;

        const aditionalFields =
            additionalFieldHeaders && recordData?.additionalFields
                ? getAditionalFieldsValues(additionalFieldHeaders, recordData?.additionalFields)
                : {};
        const formState = Object.keys(baseFieldHeaders)
            .sort()
            .reduce((acc, key) => {
                const value = ['string', 'number', 'boolean', 'date', 'datetime'].includes(baseFieldHeaders[key]?.dataType || '')
                    ? recordData[key as keyof IRecordHeaders]
                    : (recordData[key as keyof IRecordHeaders] as Record<string, any>)?.id;
                return { ...acc, [key]: value };
            }, {});
        setInitialFormState({
            ...formState,
            ...(aditionalFields || {})
        });
        methods.reset({
            ...formState,
            ...(aditionalFields || {})
        });
    }, [additionalFieldHeaders, recordData, baseFieldHeaders, isCreate, methods]);

    // create form for new record
    useEffect(() => {
        if (isCreate && baseFieldHeaders && changePanel === PanelTitle.Edit) {
            const initialState = generateInitialState(baseFieldHeaders, recordType);
            setInitialFormState(initialState);
            if (!methods.formState.defaultValues) methods.reset(initialState);
        }
    }, [baseFieldHeaders, changePanel, isCreate, methods, recordType]);

    useEffect(() => {
        if (!isEditMode) return;
        if (changePanel === PanelTitle.Edit) isEditMode(true);
        else isEditMode(false);
    }, [changePanel, isEditMode]);

    return (
        <div
            data-testid="properties-panel"
            style={{
                height: '100%',
                display: changePanel === PanelTitle.ViewObject || changePanel === PanelTitle.EditObject ? 'flex' : 'block',
                flexDirection: 'column'
            }}
        >
            <FormProvider {...methods}>
                <HeaderPanel
                    isCreate={isCreate}
                    title={
                        changePanel === PanelTitle.LogReport
                            ? 'Record History'
                            : changePanel === PanelTitle.ViewObject || changePanel === PanelTitle.EditObject
                            ? `${selectedObjectToEdit?.name ?? 'Object'}`
                            : 'Details'
                    }
                    changePanel={changePanel}
                    setLogReportMode={setLogReportMode}
                    isOpenForm={changePanel === PanelTitle.Edit}
                    toggleEditForm={toggleEditForm}
                    onShowFullHistory={() => {
                        setShowFullHistory(true);
                        dispatch(openDrawer(false));
                    }}
                    showHistory={changePanel === PanelTitle.LogReport}
                    toggleLogReport={toggleLogReport}
                    onToggleLineItems={onToggleLineItems}
                    isObjectPanel={changePanel === PanelTitle.ViewObject || changePanel === PanelTitle.EditObject}
                    onGoBack={() => {
                        setPrevChangePanel(PanelTitle.View);
                        setChangePanel(prevChangePanel);
                    }}
                    onToggleComments={onToggleComments}
                    onToggleAttachments={onToggleAttachments}
                    recordTypeId={Number(recordType)}
                />
                <Box
                    sx={{
                        height:
                            changePanel === PanelTitle.ViewObject || changePanel === PanelTitle.EditObject
                                ? 'calc(100% - 60px)'
                                : 'calc(100% - 125px)',
                        '& > *:first-child': { overflowY: 'auto', height: '100%' }
                    }}
                >
                    {changePanel === PanelTitle.View && (
                        <ViewPanel
                            fields={viewFields}
                            loading={!!loading || loadingRecordData || loadingListValues}
                            headerData={headers || null}
                            handleOpenViewObjectPanel={handleOpenViewObjectPanel}
                        />
                    )}

                    {changePanel === PanelTitle.ViewObject && (
                        <ObjectPanelView
                            objectName={`${selectedObjectToEdit?.name}`}
                            loadingObjectProperties={loadingObjectProperties}
                            handleClosePanel={() => {
                                setChangePanel(PanelTitle.View);
                                setObjectProperties([]);
                            }}
                            handleOpenEditPanel={() => {
                                setChangePanel(PanelTitle.EditObject);
                            }}
                            objectProperties={objectProperties}
                            isRecordViewer
                            renderEditButton={isAllowedToEditObject}
                            alwaysShowButtons
                        />
                    )}

                    {changePanel === PanelTitle.EditObject && (
                        <ObjectPanelEditForm
                            objectName={`${selectedObjectToEdit?.name}`}
                            showTopCloseButton
                            objectProperties={objectProperties}
                            handleUpdateObjectViewer={handleSaveEditObjectFormActions}
                            handleClosePanelViewer={handleSaveEditObjectFormActions}
                            isRecordViewer
                        />
                    )}

                    {(changePanel === PanelTitle.Edit ||
                        changePanel === PanelTitle.EditObject ||
                        changePanel === PanelTitle.ViewObject) && (
                        <EditPanel
                            isCreate={isCreate}
                            recordTypeId={recordType}
                            onChangeRecordType={(val) => setRecordTypeId(val)}
                            baseFieldHeaders={baseFieldHeaders}
                            aditionalFieldHeader={additionalFieldHeaders}
                            loadingFields={loadingRecordData || loadingListValues}
                            fieldAccessList={fieldAccess}
                            recordData={recordData}
                            customListValues={listValuesByListId}
                            inMemoryObjectProperties={inMemoryObjectProperties}
                            handleOpenViewObjectPanel={handleOpenViewObjectPanel}
                            isObjectPanelOpen={changePanel === PanelTitle.EditObject || changePanel === PanelTitle.ViewObject}
                        />
                    )}

                    {changePanel === PanelTitle.LogReport && (
                        <LogReportPanel
                            recordId={Number(recordData?.id)}
                            showFullHistory={showFullHistory}
                            onCloseFullHistory={() => setShowFullHistory(false)}
                            logReportMode={logReportMode}
                        />
                    )}
                </Box>
                {!(
                    changePanel === PanelTitle.ViewObject ||
                    changePanel === PanelTitle.EditObject ||
                    changePanel === PanelTitle.LogReport
                ) && (
                    <Footer
                        panelState={changePanel}
                        onEdit={toggleEditForm}
                        onSave={handleClickSave}
                        onCancel={handleClickCancel}
                        isCreate={isCreate}
                        disabledButtons={loading || loadingRecordData}
                    />
                )}
                {changePanel === PanelTitle.LogReport && (
                    <FooterHistoryPanel
                        onShowFullHistory={() => {
                            setShowFullHistory(true);
                            dispatch(openDrawer(false));
                        }}
                    />
                )}
            </FormProvider>
        </div>
    );
};

export default PropertiesPanelv3;
