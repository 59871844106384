import React from 'react';
import { StyledChip } from './components';
import { Box, Stack } from '@mui/material';
import { useIntl } from 'react-intl';

export type InlineOptionPickerProps = {
    options: { label: string; value: string | number }[];
    error?: boolean;
    fullWidth?: boolean;
    width?: string;
    clearable?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    multiple?: boolean;
} & (
    | {
          multiple: true;
          value: string[] | number[];
          onChange: (newValue: string[] | number[]) => void;
      }
    | { multiple?: false | undefined; value: string | number | undefined; onChange: (newValue: string) => void }
);

export const InlineOptionPicker = ({
    options,
    error,
    fullWidth,
    width,
    clearable = true,
    readOnly,
    disabled,
    ...props
}: InlineOptionPickerProps) => {
    const intl = useIntl();
    const handleClick = (newVal: any) => () => {
        if (props.multiple) {
            const alreadyExist = props.value.some((val) => val === newVal);
            // TODO: fix this type issue
            // @ts-ignore
            if (alreadyExist) props.onChange(props.value.filter((val) => val !== newVal));
            else props.onChange([...props.value, newVal]);
        } else if (clearable) props.onChange(newVal !== props.value ? newVal : '');
        else props.onChange(newVal);
    };

    return (
        <Box>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    background: disabled ? '#e0e0e0' : '#f5f6f7',
                    borderRadius: '8px',
                    border: error ? '1px solid #EF5350' : 'none',
                    width: width || (fullWidth ? '100%' : 'fit-content'),
                    px: '12px',
                    py: '4px',
                    position: 'relative'
                }}
            >
                {options.map((option) => (
                    <StyledChip
                        key={option.value}
                        label={intl.formatMessage({ id: option.label, defaultMessage: option.label })}
                        variant="outlined"
                        selected={props.multiple ? props.value.some((val) => val === option.value) : props.value === option.value}
                        onClick={handleClick(option.value)}
                    />
                ))}
                {(readOnly || disabled) && <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />}
            </Stack>
        </Box>
    );
};
