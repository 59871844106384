import { Link } from '@mui/icons-material';
import { Grid, Typography, IconButton, Box } from '@mui/material';

export type ObjectInputFieldProps = {
    objectValues: Record<string, any>;
    onOpen: () => void;
    propertiesById: Record<number, any>;
};

export const ObjectInputField = ({ objectValues, onOpen, propertiesById }: ObjectInputFieldProps) => (
    <Box
        component={Grid}
        justifyContent="space-between"
        alignItems="center"
        container
        sx={{
            width: '100%',
            height: '37px',
            px: '12px',
            bgcolor: '#f5f6f7',
            borderRadius: '8px'
        }}
    >
        {!!Object.values(objectValues).length && (
            <Box component={Grid} onClick={onOpen} item xs="auto" sx={{ cursor: 'pointer', maxWidth: 'calc(100% - 40px) !important' }}>
                <Typography
                    fontSize="14px"
                    fontWeight={500}
                    sx={{
                        textDecoration: 'underline',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                    color="secondary"
                >
                    {[...Object.keys(objectValues)]
                        .sort((a, b) => propertiesById[+a].order - propertiesById[+b].order)
                        .map((key) => objectValues[+key])
                        .join(', ')}
                </Typography>
            </Box>
        )}
        <Grid item xs="auto" sx={{ ml: 'auto' }}>
            <IconButton sx={{ px: 0 }} onClick={onOpen} disableRipple>
                <Link />
            </IconButton>
        </Grid>
    </Box>
);
