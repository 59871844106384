import { Chip, ChipProps, styled } from '@mui/material';

export const StyledChip = styled(Chip)<ChipProps & { selected?: boolean }>(({ theme, selected }) => ({
    height: '28px',
    fontSize: '12px',
    fontWeight: 500,
    color: selected ? theme.palette.secondary[800] : '#54595E',
    backgroundColor: selected ? theme.palette.secondary.light : 'white',
    borderColor: selected ? theme.palette.secondary[800] : '#54595E'
}));
