import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, ClickAwayListener, IconButton, SxProps, Typography } from '@mui/material';
import { ChangeEvent, MouseEvent, ReactNode, useRef, useState } from 'react';
import { CustomMultiSelectOption } from './types';
import { List, CustomMultiSelectContainer } from './components';

export type CustomMultiSelectProps = {
    options: CustomMultiSelectOption[];
    value: number[];
    onChange: (selectedOptions: number[]) => void;
    leftButtonLabel?: ReactNode;
    width?: string;
    sx?: SxProps;
    label?: JSX.Element;
    variant?: 'outlined' | 'standalone';
    ExpandIcon?: JSX.Element;
    ContractIcon?: JSX.Element;
};

export const CustomMultiSelect = ({
    options,
    value,
    onChange,
    leftButtonLabel,
    width,
    sx,
    label,
    variant = 'standalone',
    ExpandIcon,
    ContractIcon
}: CustomMultiSelectProps) => {
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchText, setSearchText] = useState('');
    // Made this way to ensure that the render match the configured order
    const [selectedOptions, setSelectedOptions] = useState(options.filter((el) => value.includes(el.id)).map((el) => el.id));
    const filteredOptions = options.filter((el) => el.label.toLowerCase().includes(searchText.toLowerCase()));
    const open = Boolean(anchorEl);

    const handleOpenOptions = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setTimeout(() => {
            if (searchInputRef.current) searchInputRef.current.focus();
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value;
        setSearchText(newVal);
    };

    const handleChangeSelectedOptions = (selectedIds: number[]) => () => {
        // Made this way to ensure that the render match the configured order
        setSelectedOptions(options.filter((el) => selectedIds.includes(el.id)).map((el) => el.id));
        onChange(options.filter((el) => selectedIds.includes(el.id)).map((el) => el.id));
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <>
                {variant === 'outlined' ? (
                    <CustomMultiSelectContainer label={label} ref={containerRef} open={open}>
                        <Box
                            ref={containerRef}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: width || '200px',
                                height: '100%',
                                ...sx
                            }}
                        >
                            {selectedOptions.length > 0 && (
                                <Typography
                                    fontSize="14px"
                                    sx={{
                                        cursor: value ? 'pointer' : 'auto',
                                        maxHeight: '100%',
                                        maxWidth: '90%',
                                        flexGrow: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {options
                                        .filter((el) => selectedOptions.includes(el.id))
                                        .map((el) => el.label)
                                        .join(', ')}
                                </Typography>
                            )}
                            {selectedOptions.length > 0 && (
                                <IconButton disableRipple onClick={handleChangeSelectedOptions([])} sx={{ ml: 'auto', pr: 0 }}>
                                    <Close htmlColor="#54595E" />
                                </IconButton>
                            )}
                            <IconButton disableRipple onClick={handleOpenOptions} sx={{ ml: 'auto', px: 0 }}>
                                {open
                                    ? ContractIcon ?? <ExpandLess htmlColor="#54595E" />
                                    : ExpandIcon ?? <ExpandMore htmlColor="#54595E" />}
                            </IconButton>
                            <List
                                open={open}
                                anchorEl={anchorEl}
                                searchInputRef={searchInputRef}
                                containerRef={containerRef}
                                searchText={searchText}
                                options={options}
                                filteredOptions={filteredOptions}
                                onClose={handleClose}
                                onChangeSearch={handleChangeSearch}
                                selectedOptions={selectedOptions}
                                onSelectOption={handleChangeSelectedOptions}
                                onClearSearch={() => setSearchText('')}
                            />
                        </Box>
                    </CustomMultiSelectContainer>
                ) : (
                    <Box
                        ref={containerRef}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: width || '200px',
                            height: '40px',
                            ...sx
                        }}
                    >
                        {selectedOptions.length > 0 && (
                            <Typography
                                fontSize="14px"
                                sx={{
                                    cursor: value ? 'pointer' : 'auto',
                                    maxHeight: '100%',
                                    maxWidth: '90%',
                                    flexGrow: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {options
                                    .filter((el) => selectedOptions.includes(el.id))
                                    .map((el) => el.label)
                                    .join(', ')}
                            </Typography>
                        )}
                        {selectedOptions.length > 0 && (
                            <IconButton disableRipple onClick={handleChangeSelectedOptions([])} sx={{ ml: 'auto', pr: 0 }}>
                                <Close htmlColor="#54595E" />
                            </IconButton>
                        )}
                        <IconButton disableRipple onClick={handleOpenOptions} sx={{ ml: 'auto' }}>
                            {open ? ContractIcon ?? <ExpandLess htmlColor="#54595E" /> : ExpandIcon ?? <ExpandMore htmlColor="#54595E" />}
                        </IconButton>
                        <List
                            open={open}
                            anchorEl={anchorEl}
                            searchInputRef={searchInputRef}
                            containerRef={containerRef}
                            searchText={searchText}
                            options={options}
                            filteredOptions={filteredOptions}
                            onClose={handleClose}
                            onChangeSearch={handleChangeSearch}
                            selectedOptions={selectedOptions}
                            onSelectOption={handleChangeSelectedOptions}
                            onClearSearch={() => setSearchText('')}
                        />
                    </Box>
                )}
            </>
        </ClickAwayListener>
    );
};
