import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, List, Typography } from '@mui/material';
import { QUERY_GET_FILTER_AUDIT_LOGS } from 'graphql/queries/records';
import { AuditLog, GetFilterAuditLogsVariables, QueryGetFilterAuditLogs } from 'ui-component/RecordView/types';
import { DetailsDialog, FullHistoryDialog } from 'ui-component/RecordView/Dialogs';
import { LogItem, LogReportSelector, SkeletonLoader, SkeletonLoaderList } from './components';
import { LogReportMode } from '../utils';
import { SearchBar } from 'ui-component/SearchBar';

export type LogReportPanelProps = {
    recordId: number;
    showFullHistory: boolean;
    onCloseFullHistory: () => void;
    logReportMode: string;
    striped?: boolean;
} & ({ showSearch?: false } | { showSearch: true; onChangeSelection?: (newSelection: LogReportMode) => void });

export const LogReportPanel = ({
    recordId,
    showFullHistory,
    onCloseFullHistory,
    logReportMode = LogReportMode.All,
    striped,
    ...props
}: LogReportPanelProps) => {
    const tenantId = Number(localStorage.getItem('tenant_id'));
    const { loading, data, fetchMore } = useQuery<QueryGetFilterAuditLogs, GetFilterAuditLogsVariables>(QUERY_GET_FILTER_AUDIT_LOGS, {
        variables: {
            data: { filterInput: { recordId, tenantId }, pagination: { limit: 25, offset: 0 } }
        }
    });

    const [selectedLog, setSelectedLog] = useState<AuditLog | null>(null);
    const [searchInput, setSearchInput] = useState('');

    const logs = useMemo(() => data?.filterAuditLogsForRecord.logs || [], [data?.filterAuditLogsForRecord.logs]);
    const total = useMemo(() => data?.filterAuditLogsForRecord.total || 0, [data?.filterAuditLogsForRecord.total]);

    const filteredLogs = logs.filter(
        ({ user, action }) =>
            user.name.toLowerCase().includes(searchInput.toLowerCase()) || action.toLowerCase().includes(searchInput.toLowerCase())
    );

    const handleFetchMore = () => {
        fetchMore({
            variables: {
                data: {
                    filterInput: { recordId, tenantId },
                    pagination: { limit: 25, offset: logs?.length }
                }
            }
        });
    };

    const handleSelectLog = (log: AuditLog) => () => {
        setSelectedLog(log);
    };

    const getFullHistory = () => {
        switch (logReportMode) {
            case LogReportMode.Objects:
                return (logs ?? []).filter((log) => log.objectsLogs.length > 0);

            default:
                return logs;
        }
    };

    if (loading) return <SkeletonLoaderList />;

    return (
        <>
            {props.showSearch && (
                <Grid container alignItems="center" sx={{ mb: '12px' }}>
                    <Grid item xs>
                        <SearchBar
                            value={searchInput}
                            placeholder="Find in current page"
                            size="small"
                            InputProps={{ sx: { bgcolor: 'white' } }}
                            onChange={(e) => setSearchInput(e.target.value)}
                            hideKeys
                            fullWidth
                        />
                    </Grid>
                    {props.onChangeSelection && (
                        <Grid item xs="auto" sx={{ ml: '4px' }}>
                            <LogReportSelector onChangeSelection={props.onChangeSelection} />
                        </Grid>
                    )}
                </Grid>
            )}
            <List id="scrollable-list">
                <InfiniteScroll
                    dataLength={logs?.length || 25}
                    next={handleFetchMore}
                    hasMore={logs?.length !== total}
                    loader={<SkeletonLoader />}
                    endMessage={
                        <Typography align="center" variant="caption" sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            No more data to load.
                        </Typography>
                    }
                    scrollableTarget="scrollable-list"
                >
                    <>
                        {logReportMode === LogReportMode.All && (
                            <>
                                {filteredLogs?.map((log, idx) => (
                                    <LogItem
                                        key={idx}
                                        item={log}
                                        onOpen={handleSelectLog(log)}
                                        striped={striped ? !!((idx + 1) % 2) : false}
                                    />
                                ))}
                            </>
                        )}
                        {logReportMode === LogReportMode.Objects && (
                            <>
                                {(filteredLogs ?? [])
                                    .filter((log) => log.objectsLogs.length > 0)
                                    .map((log, idx) => (
                                        <LogItem key={idx} item={log} onOpen={handleSelectLog(log)} />
                                    ))}
                            </>
                        )}
                    </>
                </InfiniteScroll>
            </List>
            <DetailsDialog item={selectedLog} onClose={() => setSelectedLog(null)} />
            {showFullHistory && (
                <FullHistoryDialog
                    items={getFullHistory()}
                    open={showFullHistory}
                    onClose={onCloseFullHistory}
                    onFetchMore={handleFetchMore}
                    total={total}
                    logReportMode={logReportMode}
                />
            )}
        </>
    );
};
