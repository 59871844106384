import { gql } from '@apollo/client';

export const QUERY_FIND_LINE_ITEMS = gql`
    query findLineItems($data: FindLineItemsInput!) {
        findLineItems(data: $data) {
            id
            tag
            value
            index
            createdAt
            updateAt
            lineItemsRow {
                id
                enabled
            }
        }
    }
`;

export const QUERY_FIND_LINE_ITEMS_HEADERS = gql`
    query FindLineItemsByType($data: FindLineItemsByTypeInput!) {
        findLineItemsByType(data: $data) {
            id
            name
            description
            visibility
            enabled
            recordType {
                id
                name
            }
            lineItemsByTypeFileds {
                id
                order
                recordAdditionalFields {
                    id
                    name
                }
            }
            createdAt
            updateAt
        }
    }
`;

export const QUERY_FIND_ALL_LINE_ITEMS_HEADERS = gql`
    query FindAllLineItemsByType {
        findAllLineItemsByType {
            id
            name
            description
            visibility
            enabled
            recordType {
                id
                name
            }
            lineItemsByTypeFileds {
                id
                order
                recordAdditionalFields {
                    id
                    name
                    dataType
                    listType {
                        id
                    }
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const QUERY_GET_FILTER_AUDIT_LOGS_FOR_LINE_ITEMS = gql`
    query FilterAuditLogsForLineItems($data: GetAuditLogRecordInputPaginated!) {
        filterAuditLogsForLineItems(data: $data) {
            logs {
                changes {
                    action
                    newValue
                    oldValue
                    what
                }
                row {
                    id
                    enabled
                    createdAt
                    updatedAt
                }
                user {
                    id
                    name
                    firstName
                    lastName
                }
                datetime
                rowAction
            }
            total
        }
    }
`;
