import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';
import { Avatar, Box, Checkbox, Chip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

export const lineItemLogsColumns = [
    {
        field: 'id',
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                ID
            </Typography>
        ),
        width: 180,
        renderCell: ({ row }: GridRenderCellParams) => {
            const arr = row.id.split(' ');

            return arr[0];
        },
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'name',
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Name
            </Typography>
        ),
        width: 180,
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'before',
        width: 336,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Before
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => {
            if (String(row.before) === 'true')
                return (
                    <Checkbox
                        disabled
                        checked
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            color: theme.palette.primary[400],
                            cursor: 'default !important',
                            transition: 'none !important',
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            if (String(row.before) === 'false')
                return (
                    <Checkbox
                        disabled
                        checked={false}
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            color: theme.palette.primary[400],
                            cursor: 'default !important',
                            transition: 'none !important',
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            return (
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 200
                    }}
                >
                    {row.before}
                </span>
            );
        },
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'after',
        width: 336,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                After
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => {
            if (String(row.after) === 'true')
                return (
                    <Checkbox
                        disabled
                        checked
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            cursor: 'default !important',
                            transition: 'none !important',
                            color: theme.palette.primary[400],
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            if (String(row.after) === 'false')
                return (
                    <Checkbox
                        disabled
                        checked={false}
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            cursor: 'default !important',
                            transition: 'none !important',
                            color: theme.palette.primary[400],
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            return (
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 200
                    }}
                >
                    {row.after}
                </span>
            );
        },
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'activity',
        width: 100,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Activity
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {row.activity && (
                    <Chip
                        label={
                            <Typography
                                textTransform="capitalize"
                                sx={{
                                    color: '#858585',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '19.9px',
                                    letterSpacing: '0.4px'
                                }}
                            >
                                <FormattedMessage id={row.activity} />
                            </Typography>
                        }
                        sx={{
                            border: `1px solid #858585`,
                            backgroundColor: 'transparent',
                            minWidth: '80px'
                        }}
                    />
                )}
            </Box>
        ),
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'user',
        width: 160,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                User
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => (
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {row?.user && (
                    <>
                        <Box>
                            <Avatar
                                sx={(theme) => ({
                                    bgcolor: '#FFF',
                                    border: `1px solid ${theme.palette.secondary.main}`,
                                    width: 24,
                                    height: 24,
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center'
                                })}
                            >
                                {row?.user?.split('')[0]}
                            </Avatar>
                        </Box>
                        <Box>
                            <Typography
                                sx={(theme) => ({
                                    color: '#54595E',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '21.98px',
                                    letterSpacing: '0.1px'
                                })}
                            >
                                {`${row?.user}`}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        ),
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'dateUpdated',
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Date Updated
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => (
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {row.dateUpdated && (
                    <Box>
                        <Typography
                            sx={(theme) => ({
                                color: '#54595E',
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '21.98px',
                                letterSpacing: '0.1px'
                            })}
                        >
                            {format(new Date(row.dateUpdated), 'MMM d, y - h:mmaaaa')}
                        </Typography>
                    </Box>
                )}
            </Box>
        ),
        width: 220,
        hideSortIcons: true,
        sortable: false
    }
];
