import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_FILTER_AUDIT_LOGS_FOR_LINE_ITEMS } from 'graphql/queries/lineItems';

const useGetFilterAuditLogsForLineItems = () => {
    const [getAuditLogForLineItems, { data: dataLogsLineItems, loading: loadingLogsLineItems }] = useLazyQuery(
        QUERY_GET_FILTER_AUDIT_LOGS_FOR_LINE_ITEMS
    );

    return {
        getAuditLogForLineItems,
        dataLogsLineItems,
        loadingLogsLineItems
    };
};

export default useGetFilterAuditLogsForLineItems;
