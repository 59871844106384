import { gql } from '@apollo/client';

export const QUERY_GET_OBJECT_PROPERTIES = gql`
    query Query($data: FindObjectPropertiesInput!) {
        findObjectProperty(data: $data) {
            id
            name
            listType {
                id
                listName
            }
            order
            isDisplayable
            isRequired
            enabled
            dataType
            objectDefinition {
                id
                name
            }
        }
    }
`;

export const QUERY_FIND_DYNAMIC_OBJECTS = gql`
    query FindDynamicObjects($data: FindDynamicObjects!) {
        findDynamicObjects(data: $data) {
            id
            objectDefinition {
                id
                name
            }
            objectValues {
                value
                tag
            }
        }
    }
`;

export const QUERY_FIND_OBJECT = gql`
    query FindObjects($data: FindObjectsInput!) {
        findObjects(data: $data) {
            id
            name
            description
            enabled
            createdBy {
                id
                name
            }
            updatedBy {
                id
                name
            }
            objectProperties {
                id
                name
                enabled
                isRequired
                isDisplayable
                dataType
                order
                createdAt
                createdBy {
                    id
                    name
                }
                listType {
                    id
                }
            }
            createdAt
            updatedAt
        }
    }
`;
