/* eslint-disable react/destructuring-assignment */
import { ToggleChip, ToggleChipProps } from '..';
import { Box, Grid } from '@mui/material';

export const ToggleChipWithBackground = (props: ToggleChipProps) => (
    <Box
        component={Grid}
        container
        justifyContent="center"
        alignItems="center"
        sx={{ px: '9px', bgcolor: props.disabled ? '#e0e0e0' : '#f5f6f7', height: '37px', borderRadius: '8px' }}
    >
        <Grid item xs="auto" sx={{ height: 'fit-content' }}>
            <ToggleChip {...props} />
        </Grid>
    </Box>
);
