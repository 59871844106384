import { useGridApiContext, GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { UsersDropdown } from '../UsersDropdown';
import { StatusRecordDropdown } from '../StatusRecordDropdown';
import { ProblemCodeDropdown } from '../ProblemCodeDropdown';
import { ListDropdown } from '../ListDropdown';
import { ListValue } from 'views/backoffice/CustomLists/types';
import { DateEditCell } from '../DateEditCell';
import { DateTimeEditCell } from '../DateTimeEditCell';
import { TimeEditCell } from '../TimeEditCell';
import { NumberEditCell } from '../NumberEditCell';
import { TargetTenantDropdown } from '../TargetTenantDropdown';
import { CustomMultiSelect } from 'ui-component/formComponents/CustomMultiSelect';
import { isJsonString } from 'utils/stringHelpers';

export const UsersEditDropdown = (props: GridRenderEditCellParams) => {
    const { id, field, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    // If the value is a number, it means that the row was edited and the value is the new id
    const wasEdited = typeof value === 'number';
    return <UsersDropdown onChange={handleValueChange} initialValue={wasEdited ? value : row[field]} fieldName={field} />;
};

export const StatusEditDropdown = (props: GridRenderEditCellParams & { recordTypeId: number }) => {
    const { id, field, recordTypeId, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const wasEdited = typeof value === 'number';

    return <StatusRecordDropdown onChange={handleValueChange} initialValue={wasEdited ? value : row[field]} recordTypeId={recordTypeId} />;
};

export const ProblemCodeEditDropdown = (props: GridRenderEditCellParams & { recordTypeId: number }) => {
    const { id, field, recordTypeId, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const wasEdited = typeof value === 'number';
    return <ProblemCodeDropdown onChange={handleValueChange} initialValue={wasEdited ? value : row[field]} recordTypeId={recordTypeId} />;
};

export const TargetTenantEditDropdown = (props: GridRenderEditCellParams & { recordTypeId: number }) => {
    const { id, field, recordTypeId, row, value } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const wasEdited = typeof value === 'number';
    return <TargetTenantDropdown onChange={handleValueChange} initialValue={wasEdited ? value : row[field]} recordTypeId={recordTypeId} />;
};

export const ListEditDropdown = (
    props: GridRenderEditCellParams & { listOfObjects?: boolean; listId?: number; listValues?: ListValue[] }
) => {
    const { id, field, listId, value, listValues, listOfObjects } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | string[]) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <ListDropdown
            key={id}
            onChange={handleValueChange}
            initialValue={value}
            listId={listId}
            listValues={listValues}
            isListOfObjects={listOfObjects}
        />
    );
};

export const MultiSelectEditDropdown = (props: GridRenderEditCellParams & { listId: number; listValues?: ListValue[] }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, field, listId, value, listValues } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <CustomMultiSelect
            value={(
                listValues?.filter((option: any) =>
                    String(value) !== '0' && isJsonString(value) && Array.isArray(JSON.parse(value))
                        ? JSON.parse(value || '[]').includes(+option.id)
                        : false
                ) ?? []
            ).map((option: any) => +option.id)}
            onChange={(newValue: number[]) => {
                handleValueChange(newValue.length === 0 ? '0' : JSON.stringify(newValue));
            }}
            options={(listValues ?? [])
                .filter((option: any) => {
                    let currentValue = [];
                    if (String(value) !== '0' && isJsonString(value) && Array.isArray(JSON.parse(value))) {
                        currentValue = JSON.parse(value || '[]');
                    }
                    return option.enabled || currentValue.includes(+option.id);
                })
                .sort((a, b) => a.order - b.order)
                .map((option: any) => ({
                    id: +option.id,
                    label: option.userValue ? option.userValue.name : option.value,
                    enabled: option.enabled
                }))}
            width="100%"
            variant="standalone"
            sx={{ paddingX: '12px' }}
        />
    );
};

export const DateEditCellDropdown = (props: GridRenderEditCellParams & { hideLabel?: boolean }) => {
    const { id, field, value, hideLabel } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <DateEditCell key={id} onChange={handleValueChange} value={value} field={field} hideLabel={hideLabel} />;
};

export const DateTimeEditCellDropdown = (props: GridRenderEditCellParams & { hideLabel?: boolean }) => {
    const { id, field, value, hideLabel } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string | null | Date) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <DateTimeEditCell key={id} onChange={handleValueChange} value={value} field={field} hideLabel={hideLabel} />;
};

export const TimeEditCellDropdown = (props: GridRenderEditCellParams & { hideLabel?: boolean }) => {
    const { id, field, value, hideLabel } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <TimeEditCell key={id} onChange={handleValueChange} value={value} field={field} hideLabel={hideLabel} />;
};

export const NumberEditCellDropdown = (props: GridRenderEditCellParams & { isCurrency: boolean }) => {
    const { id, field, value, isCurrency } = props;
    const apiRef = useGridApiContext();
    const handleValueChange = (newValue: string) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <NumberEditCell key={id} onChange={handleValueChange} value={value} isCurrency={isCurrency} />;
};
