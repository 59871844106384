import { Button, Grid, SxProps, Theme } from '@mui/material';

export type FooterHistoryPanelProps = {
    onShowFullHistory: () => void;
    label?: string;
    sx?: SxProps<Theme> | undefined;
};

export const FooterHistoryPanel = ({ onShowFullHistory, label = 'Show full history', sx }: FooterHistoryPanelProps) => (
    <Grid container justifyContent="flex-start" sx={{ borderTop: 'solid 1px #D9D9D9', py: '12px', px: '12px' }}>
        <Button variant="outlined" color="secondary" sx={{ borderRadius: '8px', ...(sx || {}) }} onClick={onShowFullHistory}>
            {label}
        </Button>
    </Grid>
);

export default FooterHistoryPanel;
