import React, { useMemo } from 'react';
import { useSelector } from 'store';
import useMyRole from './useMyRole';

const useIsAllowed = (action: string) => {
    const roleId = useMyRole();
    const { actionData, selectedItem } = useSelector((store) => store.menu);

    const isAllowed = useMemo(
        () =>
            actionData
                .find((el) => Number(el.menuItem.id) === Number(selectedItem) && Number(el.role.id) === (roleId as number))
                ?.menuItemActions.find((el) => el.name.toLowerCase().includes(action))?.enabled,
        [action, actionData, roleId, selectedItem]
    );

    return {
        isAllowed
    };
};

export default useIsAllowed;
