import { useLazyQuery } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import { Box, IconButton, MenuItem, Popover, Tooltip, Typography } from '@mui/material';
import { QUERY_FIND_LIST_VALUES } from 'graphql/queries/lists';
import { useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import { isJsonString } from 'utils/stringHelpers';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';

export type MultiselectGridRenderProps = {
    value: string;
    listValues?: ListValue[];
    listId?: number;
};

export const MultiselectGridRender = ({ listId, value, listValues }: MultiselectGridRenderProps) => {
    const [getListValues, { data: listValuesData }] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_FIND_LIST_VALUES, {
        variables: { data: { listId: Number(listId) } }
    });

    const values = useMemo(() => {
        const availableOptions = listValues || listValuesData?.findListValues || [];
        const diabledTag = `(disabled) `;
        if (String(value) !== '0' && isJsonString(value) && Array.isArray(JSON.parse(value))) {
            const returnValue = availableOptions
                .filter((option) => JSON.parse(value || '[]').includes(+option.id))
                .map((option) => `${option.enabled ? '' : diabledTag}${option.userValue?.name ?? option.value}`);
            console.log('!!!!! returnValue', { availableOptions, value, returnValue });
            return returnValue;
        }
        return [] as string[];
    }, [listValues, value, listValuesData]);

    useEffect(() => {
        if (listId && !listValues) getListValues();
    }, [listId, listValues, getListValues]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const multipleSelectPanelOpen = Boolean(anchorEl);

    const handleToggleMultipleSelectPanel = (event: MouseEvent<HTMLElement>) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(containerRef.current);
        }
    };

    return (
        <>
            {value && isJsonString(value) && value !== '0' && (
                <>
                    {Array.isArray(values) && (
                        <Box ref={containerRef} sx={{ width: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography
                                    style={{
                                        maxWidth: '90%',
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        paddingLeft: 10,
                                        padding: 10,
                                        fontWeight: 400,
                                        fontSize: '13px'
                                    }}
                                    color="#818181"
                                    className="gridObjectValue"
                                >
                                    {values.join(', ')}
                                </Typography>
                                {values.length > 0 && (
                                    <IconButton
                                        onClick={(e) => {
                                            handleToggleMultipleSelectPanel(e);
                                        }}
                                    >
                                        <ExpandMore htmlColor="#54595E" sx={{ cursor: 'pointer' }} />
                                    </IconButton>
                                )}
                            </Box>
                            {multipleSelectPanelOpen && (
                                <Popover
                                    anchorEl={anchorEl}
                                    open={multipleSelectPanelOpen}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    PaperProps={{
                                        sx: {
                                            borderRadius: '8px',
                                            ml: '0px',
                                            mt: '5px',
                                            width: containerRef.current?.clientWidth || '200px'
                                        }
                                    }}
                                >
                                    {values.length > 0 &&
                                        values.map((name, idx) => (
                                            <Tooltip title={name} key={`${name}-${idx}`}>
                                                <Box>
                                                    <MenuItem sx={{ height: '40px', px: '12px', bgcolor: '#FFF' }}>
                                                        <Typography
                                                            fontSize="14px"
                                                            sx={{ overflow: 'hidden', width: '100%', textOverflow: 'ellipsis' }}
                                                        >
                                                            {name}
                                                        </Typography>
                                                    </MenuItem>
                                                </Box>
                                            </Tooltip>
                                        ))}
                                </Popover>
                            )}
                        </Box>
                    )}
                </>
            )}
        </>
    );
};
